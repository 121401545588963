.cn-divider{
    width: 100%;
    height: 20px;
    border-bottom: 1px solid $color-grey;
    text-align: center;
    margin: 20px 0;

    .cn-text {
        background-color:#fff;
        color: $color-grey;
        line-height: 40px;
        padding: 0 10px;
        text-transform: uppercase;
    }
}

@media (min-width: mdc-layout-grid-breakpoint-min('tablet')) {
    .cn-divider {
        margin: 40px 0;
    }
}
