@import '../../../legacy/lib-nickel/assets/scss/common/color.scss';

.center {
    display:flex;
    flex-direction: column;
    align-items: center;

    h2.title {
        margin: 40px 0;
    }
}

.menuList {
    .menuItem {
        margin-bottom: 20px;
        text-align:center;
        .cn-link-orange {
            color: $color-compte-nickel;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
