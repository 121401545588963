/* FOOTER */
.footer {
    width: 100%;
    min-height: 300px;
    background-color: $color-grey--light;
    border-top: 1px solid $color-grey;
    padding: 26px 0 0;
    margin-top: 60px;

    .list {
        list-style: none;
        min-height: 200px;

        .item {
            height: 40px;
            display:flex;
            align-items: center;

            .link {
                font-size: 14px;
                color: black;

                &:hover {
                    color: $color-compte-nickel;
                }

                .icon {
                    &:before {
                        vertical-align: middle;
                        margin-right: 10px;
                        font-size: 20px;
                    }

                    /* &.icon-facebook-circle {
                        color: $color-facebook;
                    }

                    &.icon-twitter-circle {
                        color: $color-twitter;
                    }

                    &.icon-linkedin-circle {
                        color: $color-linkedin;
                    }*/
                }
            }
        }
    }

    .list-img .item {
        margin-bottom: 10px;

        .link .img {
            max-width: 135px;
            width: 100%;
            height: auto;
        }
    }

    .subfooter {
        background-color: white;
        width: 100%;
        height: 60px;
        margin-top: 50px;

        .footer-container {
            height: inherit;
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .bloc-copyright {
                font-weight: 500;
                font-size: 14px;
                align-self: center;
            }

            .list {
                min-height: auto;
                .item {
                    .link {
                        line-height: 40px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media (min-width: mdc-layout-grid-breakpoint-min('desktop')) {
    .footer {
        height: auto;
    }
}

@media (max-width: mdc-layout-grid-breakpoint-min('tablet')) {
    .footer {
        margin-top: 0;

        .list {
            min-height: auto;

            .link {
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
}
