@import '../../../../../assets/styles/common';
@import "@nickel/ui/scss/tokens.scss";

.language-switcher-modal {
    background: $COLOR_WHITE;
    border-radius: 100px;
    border: 1px solid $COLOR_BLACK;
    display: none;
    flex-direction: column;
    margin: 50px auto 10px auto;
    order: 3;
    padding: 10px;
    vertical-align: center;
    width: 80%;
    z-index: 10000;

    @media (min-width: $mq-tablet) {
        border: 0;
        display: flex;
        flex: 0 0 auto;
        left: unset;
        margin: 0;
        position: relative;
        top: unset;
        width: auto;
    }

    &.active {
        display: flex;
    }

    .language-switcher-modal-button {
        align-items: center;
        appearance: none;
        background: none;
        border: 0;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0px;
        position: relative;
        width: 100%;
    }

    .language-switcher-icon {
        font-size: 10px;
        margin-top: -5px;
        padding-left: 10px;
        position: absolute;
        right: 10px;
        top: 50%;
    }

    .country-name {
        font-size: 16px;
    }

    img {
        border-radius: 25px;
        height: 25px;
        margin-right: 8px;
        max-width: 25px;
        padding: 0;
        width: auto;
    }
}

.language-selector-modal {
    background: #fff;
    border-radius: 8px;
    border: solid 1px #ccc;
    box-shadow: 0 4px 16px rgba(25,31,41,.1);
    color: #000;
    height: auto;
    left: 50%;
    max-width: 700px;
    overflow: visible;
    padding: 0;
    padding: 32px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 92%;
    z-index: 1260;

    .language-selector-header {
        display: flex;
        font-size: 14px;
        justify-content: flex-end;
    }
    
    .language-selector-modal-close {
        appearance: none;
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
    }

    .language-selector-field {
        margin: 24px 0;
    }

    .language-selector-modal-label {
        color: $COLOR_BLACK;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        margin-bottom: 20px;

        &.language-label {
            margin-bottom: 12px;
        }
    }

    .language-button {
        appearance: none;
        background: transparent;
        border: 0;
        display: inline;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        padding: 0;
    
        &.active {
            color: $COLOR_SECONDARY;
        }
    }

    .language-selector {
        display: flex;
        gap: 24px;
    }

    .language-selector-button {
        display: block;
        margin: 0 auto;
        width: 100%;

        @media (min-width: $mq-tablet) {
            padding: 0 24px;
            width: auto;
        }
    }
}

.language-selector-modal-overlay {
    background: rgba(55,63,81,.6);
    height: 100%;
    left: 0;
    opacity: 1;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1259;
}

.header .language-switcher-modal .country-name {
    @media (min-width: $mq-tablet) {
        text-decoration: underline;
    }
}
