@import '../../../../../assets/styles/common';
@import "../../../../../legacy/lib-nickel/assets/scss/common/color.scss";
@import "@nickel/ui/scss/tokens.scss";

$header-height: 68px;

.header {
    height: $header-height;

    @media (min-width: $mq-tablet) {
       padding: 0 20px 0 0px;
    }
}

.wrapper-nav {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $mw-content;
}

.header-logo {
    display: flex;
    height: 35px;
    margin:0 10px;
    width: 88px;

    @media (min-width: $mq-desktop) {
        height: auto;
        margin:0 20px 0 30px;
        width: 130px;
    }

    img {
        width: 100%;
    }
}

.burger-menu {
    appearance: none;
    background: transparent;
    border:0;
    height: 16px;
    margin-right: 20px;
    margin-top: 2px;
    position: relative;
    width: 16px;

    @media (min-width: $mq-tablet) {
        display: none;
    }

    .burger-menu-button {
        background: $COLOR_BLACK;
        border-radius: 2px;
        height: 2px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &:before,
        &:after {
            background: $COLOR_BLACK;
            border-radius: 2px;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &:before {
            top: 4px;
        }

        &:after {
            top: 8px;
        }
    }

    &.active {
        .burger-menu-button {
            background: transparent;

            &:before,
            &:after {
                height: 1px;
                left: 0;
                top: 4px;
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }

    .burger-menu-button-text {
        display: none;
    }
}

.wrapper-menu {
    &.active {
        background: $COLOR_WHITE;
        box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.12);
        display:flex;
        flex-direction: column;
        height: auto;
        left: 0;
        padding: 16px;
        position:absolute;
        top: $header-height;
        width: 100%;
        z-index: 1000;
    }

    &,
    &.active {
        @media (min-width: $mq-tablet) {
            align-items: center;
            box-shadow: none;
            display: flex;
            flex-direction: row;
            height: $header-height;
            justify-content: space-between;
            padding: 0;
            position: static;
            width: 100%;
        }
    }
}

.header-nav {
    display: none;
    margin-top: 20px;
    order: 2;

    &.active {
        display: block;

        @media (min-width: $mq-tablet) {
            display: flex;
            order: 1;
        }
    }

    @media (min-width: $mq-tablet) {
        display: flex;
        flex-direction: row;
        flex: 0 1 auto;
        height: 100%;
        justify-content: flex-start;
        margin-top: 0px;
        order: 1;
    }

    li {
        align-items: center;
        display: flex;
        font-size: 18px;
        font-weight: 700;
        justify-content: space-between;       

        &.active a {
            color: $color-compte-nickel;

            @media (min-width: $mq-desktop) {
                border-bottom: 1px solid $color-compte-nickel;
            }
        } 

        a {
            color: $COLOR_BLACK;
            font-size: 18px;
            font-weight: 700;

            @media (min-width: $mq-tablet) {
                align-items: center;
                display: flex;
                flex-direction: column;
                flex: 0 0 auto;
                font-size: 14px;
                font-weight: 300;
                margin: 0 6px;
                padding: 0 6px;
            }

            @media (min-width: $mq-desktop) {
                font-size: 14px;
                font-weight: 700;
                height: 100%;
                justify-content: center;
            }

            svg {
                display: none;

                @media (min-width: $mq-tablet) {
                    display: block;
                }

                @media (min-width: $mq-desktop) {
                    display: none;
                }
            }
        }

        span {
            @media (min-width: $mq-tablet) {
                align-items: center;
                display: flex;
                flex-direction: row;
                font-size: 16px;
                height: 16px;
            }

            svg + svg {
                font-size: 30px;
            }

            @media (min-width: $mq-desktop) {
                display: none;
            }
        }

        .icon {
            @media (min-width: $mq-desktop) {
                display: none;
            }
        }
    }

    &.active {
        li {
            align-items: center;
            border-bottom: 1px solid $COLOR_SECONDARY_LIGHT;
            display: flex;
            font-size: 18px;
            font-weight: 700;
            height: 64px;
            justify-content: space-between;
            width: 100%;

            @media (min-width: $mq-tablet) {
                border-bottom: 0;
                height: 100%;
                margin: 0 6px;
                padding: 0 6px;
                width: auto;
            }
    
            a {
                color: $COLOR_BLACK;
            }

            &.active a {
                color: $color-compte-nickel;
            } 
        }
    }
}

.header-links {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    order: 1;
    width: 100%;

    @media (min-width: $mq-tablet) {
        flex: 1 0 150px;
    }

    @media (min-width: $mq-desktop) {
        flex: none;
        width: auto;
    }

    &.active {
        .customer-area-link {
            display: block;

            @media (min-width: $mq-tablet) {
                display: none;
            }

            @media (min-width: $mq-desktop) {
                display: block;
                width: auto;
            }
        }

        .create-account-link {
            position: static;
            transform: none;
            width: 100%;

            @media (min-width: $mq-tablet) {
                width: auto;
            }

            @media (min-width: $mq-desktop) {
                width: auto;
            }
        }
    }

    .customer-area-link,
    .create-account-link {
        border-radius: 100px;
        font-weight: 400;
        padding: 13px 22px;
        text-align: center;
        width: 100%;

        @media (min-width: $mq-tablet) {
            padding: 10px 18px;
        }

        @media (min-width: $mq-desktop) {
            font-size: 16px;
        }
    }

    .create-account-link {
        background: $color-compte-nickel;
        border: 1px solid $color-compte-nickel;
        color: $COLOR_WHITE;
        width: auto;

        @media (min-width: $mq-tablet) {
            font-size: 16px;
            order: 2;
            padding: 10px;
            position: static;
        }

        @media (min-width: $mq-desktop) {
            padding: 10px 18px;
            width: auto;
        }
    }

    .customer-area-link {
        border-radius: 100px;
        border: 1px solid $COLOR_BLACK;
        color: $COLOR_BLACK;
        display: none;
        margin-right: 20px;

        @media (min-width: $mq-tablet) {
            display: none;
            
            width: auto;
        }

        @media (min-width: $mq-desktop) {
            display: block;
            width: auto;
        }
    }
}
