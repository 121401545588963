.cn-text-default {
  color: #000;

  .device-borne & {
    font-size: 26px;
  }

  .orange,
  &.orange {
    color:$color-compte-nickel;
  }

  &.center{
    text-align: center;
  }
}