@import "~@material/layout-grid/mdc-layout-grid";

.account-for-all {
    text-align: center;

    h4.title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .cn-text-default {
        margin-bottom: 10px;
    }
}

@media (min-width: mdc-layout-grid-breakpoint-min('desktop')) {
    .account-for-all {
        h4.title {
            font-size: 30px;
            margin-bottom: 20px;
        }

        .cn-text-default {
            margin-bottom: 20px;
        }
    }
}
