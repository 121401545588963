@import '../../../assets/styles/common.scss';

@import "../../../legacy/lib-nickel/assets/scss/component/frames/frame-border.scss";

.agencies {
    &.list {
        .list-item {
            &:hover {
                border-color: $color-compte-nickel;
                box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
            }

            .agency {
                display:flex;
                flex-direction: column;

                .icon:before {
                    color: $color-compte-nickel;
                    margin: 0 5px;
                }
            }
        }
    }
}
