@import "~@material/layout-grid/mdc-layout-grid";

.wrapper-loader{
    width: 100%;
    height: 300px;
    padding-top: 60px;
}

@media (max-width: mdc-layout-grid-breakpoint-max('tablet')) {
    .wrapper-loader{
        padding-top: 30px;
        padding-bottom: 30px;
    }
}