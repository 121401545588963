$mdc-layout-grid-breakpoints: (
        largedesktop: 1200px,
        desktop: 992px,
        tablet: 768px,
        largephone: 576px,
        phone: 0px
);

.max-width {
    max-width: 1200px;
}
