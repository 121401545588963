@import '../../../legacy/lib-nickel/assets/scss/common/color.scss';
@import '~@material/layout-grid/mdc-layout-grid';

.DepartmentList {
    line-height: 1;
    margin: 0 auto;
    max-width: 990px;
    padding: 1em;
}


.DepartmentList-items {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 3rem;

    @media (min-width: mdc-layout-grid-breakpoint-min('desktop')) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.DepartmentList-item {
    background-color: white;
    border-radius: 5px;
    border: 1px solid $color-grey;
    color: $color-compte-nickel;
    font-weight: 400;
    padding: 1rem;
    transition: 200ms all ease-in-out;

    &:hover {
        background-color: $color-grey--light;
    }
}
