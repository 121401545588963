@import '../../../assets/styles/common';
@import "../../../legacy/lib-nickel/assets/scss/component/links/links.scss";

.mdc-layout-grid {
    &.max-width-790 {
        padding: 0 20px 20px;
        max-width: 790px;
    }

    &.max-width-1200 {
        padding: 0 20px 20px;
        max-width: 1200px;
    }
}

@media (min-width: mdc-layout-grid-breakpoint-min('desktop')) {
    .mdc-layout-grid {
        &.max-width-790,
        &.max-width-1200 {
            padding: 20px;
        }
    }
}
