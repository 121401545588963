@import '../../../../../assets/styles/common.scss';
@import '../../../../../legacy/lib-nickel/assets/scss/component/footer/footer.scss';
@import "@nickel/ui/scss/tokens.scss";

.footer {
    background-color: $COLOR_WHITE;
    border-top: 0;
    margin: 0px;
    min-height: none;
    padding: 0;
    width: 100%;

    .footer-inner {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: $mw-content;
        padding: 0 24px;
    }

    .footer-wrapper {
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-tablet) {
            flex-direction: row;
        }
    }

    .footer-block-text {
        display: flex;
        flex-direction: column;
        line-height: 24px;
        margin-bottom: 20px;

        img {
            margin: 10px 0;
            max-width: 160px;

            @media (min-width: $mq-tablet) {
                margin-bottom: 24px 
            }
        }

        strong {
            font-weight: 700;
        }

        @media (min-width: $mq-tablet) {
            flex: 1 0 33%;
            font-size: 16px;
        }
    }



    .footer-wrapper-social {
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-desktop) {
            flex-direction: row-reverse;
        }
    }

    .footer-wrapper-link {
        display: flex;
        flex-direction: column;
        order: 2;

        @media (min-width: $mq-tablet) {
            flex-direction: row;
        }
    }

    .footer-block-link {
        @media (min-width: $mq-tablet) {
            padding: 0 32px;
        }
    
        .link.category {
            display: block;
            font-size: 18px;
            font-weight: 800;
            margin-bottom: 8px;
            margin-top: 16px;
            text-transform: uppercase;
        }

        li {
            margin: 10px 0;
        }

        a {
            color: $COLOR_BLACK;
            font-size: 16px;
            text-decoration: none;
        }

        @media (min-width: $mq-desktop) {
            flex: 1 0 33%;
        }
    }

    .footer-block-language {
        display: flex;
        flex-direction: column;
        margin: 24px 0;
        order: 1;


        @media (min-width: $mq-tablet) {
            flex-direction: row;
            margin: 10px 0;
        }

        @media (min-width: $mq-desktop) {
            align-self: flex-end;
            flex-direction: column;
            flex: 1 0 33%;
        }

        .language-switcher,
        .language-switcher-modal {
            display: flex;
            margin: 0 auto;

            @media (min-width: $mq-tablet) {
                border: 1px solid $COLOR_BLACK;
                flex-direction: row;
                flex: 0 0 45%;
                height: 50px;
            }

            @media (min-width: $mq-desktop) {
                margin: 0;
                width: 100%;
            }
        }

        .country-name {
            @media (min-width: $mq-tablet) {
                display: flex;
            }
        }
    }

    // TOTO

    .become-distributor-link {
        border-radius: 100px;
        border: 1px solid $COLOR_BLACK;
        color: $COLOR_BLACK;
        font-weight: 400;
        margin: 0 auto 24px auto;
        padding: 13px 22px;
        text-align: center;
        width: 80%;

        @media (min-width: $mq-tablet) {
            margin: 0 auto;
            padding: 10px 18px;
            width: auto;
        }

        @media (min-width: $mq-desktop) {
            font-size: 16px;
            margin: 20px 0;
            width: 100%;
        }
    }

    .footer-block-social {
        @media (min-width: $mq-tablet) {
            border-top: 1px solid #d4d4d4;
            display: flex;
            flex-direction: row;
            flex: 1 1 50%;
            justify-content: space-between;
            margin: 24px 0;
            padding: 24px 0 0 0;
        }

        @media (min-width: $mq-desktop) {
            align-self: flex-end;
            width: 75%;
        }
    }

    .footer-block-app {
        margin: 16px 0 24px 0;

        ul {
            display: flex;
            flex-direction: row;

            li {
                margin-right: 24px;
            }
        }
    }

    .footer-block-social-network {
        border-top: 1px solid #d4d4d4;
        margin: 24px -10px;

        @media (min-width: $mq-tablet) {
            border-top: 0;
            display: flex;
            margin: 0;
            width: 45%;
        }

        ul {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 24px 0;
            width: 100%;
        }
    }
}
