/* Typography
----------------------------------------- */

@mixin mqr-subpixel-antialiased {
    -webkit-font-smoothing: antialiased;
    /* media query for subpixel-antialiased custom font in retina device
     * src : http://www.intridea.com/blog/2014/5/8/better-font-smoothing-in-chrome-on-mac-os-x
     * used on typo and not in html tag or * selector because of font-icon use.
     */
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 120dpi), // Surface Pro 2
    only screen and (min-resolution: 1.3dppx) {
        -webkit-font-smoothing: subpixel-antialiased;
    }
}

$default-font-family: "Helvetica Neue",
Helvetica,
Arial,
sans-serif;

@mixin font {
    font-family: Muller Narrow;
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;

    @include mdc-layout-grid-media-query_('phone') {
        font-size: 16px;
        line-height: 18px;
    }
}

$medium: 500;
$bold: 800;

html, body {
    @include font;
}

b, strong {
    font-weight: $medium;
}
