@import "~@material/layout-grid/mdc-layout-grid";
@import '../../../legacy/lib-nickel/assets/scss/common/color.scss';
@import '../../../legacy/lib-nickel/assets/scss/common/typography.scss';

@import "../../../legacy/lib-nickel/assets/scss/component/texts/text-default";

.section-infos {
    margin-bottom: 20px;

    .cn-text-default {
        &.mbottom {
            margin-bottom: 20px;
        }

        .bold {
            font-size: 16px;
            font-weight:500;
        }
    }

    a.info {
        color: $color-compte-nickel;
        text-decoration:underline;
    }
}

@media (min-width: mdc-layout-grid-breakpoint-min('desktop')) {
    .section-infos {
        margin-bottom: 36px;

        .info {
            font-size: 18px;
            line-height: 24px;

            .bold {
                font-size: 18px;
            }
        }
    }
}
