// Site Web
$color-compte-nickel: #FF5F00; // Main Color
$color-compte-nickel--light: lighten($color-compte-nickel, 30%);
$color-compte-nickel--dark: #EA4E00; // Main Color Dark

// Gris
$color-grey--light: #f2f2f2; // background
$color-grey : #cccccc; // borders + box-shadow + disabled
$color-grey--dark: #666666; // texts fields

// Validation
$color-red: #C9130A; // Erreur
$color-red-light: lighten($color-red, 30%); // Background Error

$color-green: #08B230; // Success
$color-green--light: lighten($color-green, 30%); // Background Success

$color-yellow: #FCDB49; // Clavier bouton corriger (numeric)

// Réseaux Sociaux
$color-facebook: #3B5998;
$color-twitter: #55ACEE;
$color-linkedin: #007BB6;


$mdc-theme-primary: $color-compte-nickel;
$mdc-theme-secondary: $color-compte-nickel;
$mdc-theme-on-primary: white;
$mdc-theme-on-secondary: white;
