$basePathTypography: "../../" !default;

@font-face {
    font-family: 'Muller Narrow';
    src: url($basePathTypography + 'font/MullerNarrow-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url($basePathTypography + 'font/MullerNarrow-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url($basePathTypography + 'font/MullerNarrow-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url($basePathTypography + 'font/MullerNarrow-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}