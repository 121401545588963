.cn-title {
    color: #000;
    font-weight: $medium;
    &.lg{
        font-size:36px;
        line-height: 36px;
    }
    &.md{
        font-size:32px;
        line-height: 32px;
    }
    &.sm {
        font-size: 26px;
        line-height: 26px;
    }
    &.orange {
        color: $color-compte-nickel;
    }

    &.center {
        text-align: center;
    }

    .device-borne & {
        font-size: 36px;
        line-height: 36px;
    }
}

@include mdc-layout-grid-media-query_('phone') {
    .cn-title {
        &.lg {
            font-size:30px;
            line-height: 30px;
        }
        &.md {
            font-size:26px;
            line-height: 26px;
        }
        &.sm {
            font-size: 20px;
            line-height: 20px;
        }
    }
}
