@import "../../legacy/lib-nickel/assets/scss/common/config-material-grid.scss";
@import "~@material/layout-grid/mdc-layout-grid";

@import "../../legacy/lib-nickel/assets/scss/common/reset.scss";

@import "../../legacy/lib-nickel/assets/scss/common/font-face.scss";
@import "../../legacy/lib-nickel/assets/scss/common/fonticon.scss";
@import "../../legacy/lib-nickel/assets/scss/common/typography.scss";
@import "../../legacy/lib-nickel/assets/scss/common/color.scss";
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;700&display=swap');

$mq-tablet: 768px;
$mq-desktop: 992px;
$mw-content: 1200px;

* {
    font-family: Sarabun, Muller Narrow, Arial, sans-serif;
    font-weight: 300;
}
