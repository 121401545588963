@import '../../../legacy/lib-nickel/assets/scss/common/color.scss';
@import "~@material/layout-grid/mdc-layout-grid";

.agency-state {
    margin:5px 0;

    &:before {
        display:inline-block;
        vertical-align: bottom;
        content: "•";
        font-size: 32px;
        margin-right: 3px;
    }

    &.open {
        color: $color-green;
    }
    &.close {
        color: $color-red;
    }
}

@media (min-width: mdc-layout-grid-breakpoint-min('desktop')) {
    .agency-state {
        &:before {
            font-size: 60px;
            vertical-align: text-bottom;
        }
    }
}
