.frame-border {
    border: 1px solid $color-grey;
    border-radius: 10px;
    padding: 20px;

    .title {
        font-size: 18px;
        font-weight: $medium;
        color: $color-compte-nickel;
        margin-bottom: 10px;
    }
}

a.frame-border {
    display:block;

    &:hover {
        border-color: $color-compte-nickel;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
    }
}

@media (min-width: mdc-layout-grid-breakpoint-min('tablet')) {
    .title {
        font-size: 20px;
    }
}
