@import '../../../assets/styles/common.scss';
@import "../../../legacy/lib-nickel/assets/scss/component/titles/titles.scss";

.cn-title {
    &.lg {
        margin-bottom: 30px;
    }

    &.md {
        margin-bottom: 20px;
    }

    &.sm {
        margin-bottom: 10px;
    }

    &.sm {
        a {
            display:block;
            color: $color-compte-nickel;
            font-weight: 500;
            text-align: center;
            margin-top: 20px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media (min-width: mdc-layout-grid-breakpoint-min('tablet')) {
    .cn-title {
        &.sm {
            a {
                margin-top: 40px;
            }
        }
    }
}
