@import "~@material/layout-grid/mdc-layout-grid";
@import '../../../legacy/lib-nickel/assets/scss/common/color.scss';
@import "../../../legacy/lib-nickel/assets/scss/component/links/links.scss";


.wrapper-button {
    display:flex;
    justify-content: center;
}

.cn-text-default {
    &.margin-bottom {
        margin-bottom: 10px;
    }
}

@media (max-width: mdc-layout-grid-breakpoint-min('desktop')) {
    h1.title{
        font-size: 25px;
    }
}
