.cn-link {
    color: $color-compte-nickel;
    border: 0;
    background-color: transparent;

    &:hover {
        color: $color-compte-nickel;
        text-decoration: underline;
    }

    &.black {
        color: black;
        text-decoration: underline;

        &:hover {
            color: $color-compte-nickel;
            text-decoration: none;
        }
    }

    &.icon-right {
        .icon {
            margin: 0 5px;
            font-size: 18px;
            &:before {
                vertical-align: middle;
            }
        }
    }

    .device-borne &{
        font-size: 30px;
    }
}

@include mdc-layout-grid-media-query_('phone') {
    .cn-link{
        margin-right: 15px;
        &.icon-right {
            .icon {
                margin: 0 5px;
                font-size: 15px;
            }
        }
    }
}
